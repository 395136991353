export default {
    languageTxt: 'en_US',
    operationBox: {
        dropdownName: '其他功能',
        offOn: '开关灯',
        brightness2: '调光',
        changelnglat: '调整位置',
        changelnglat2: '手动调整',
        loopControl: '回路控制'
    },
    setNumAbbrFun: {
        hundred: '百',
        thousand: '千',
        tenthousand: '万',
        Lakh: '十万',
        million: '百万',
        tenmillion: '千万'
    },
    unit: {
        pcs: '只',
        pcs2: '个'
    },
    citySplit: '省',
    // SystemName: '合创智控照明系统',
    SystemName: '智能照明管控系统',
    logOut: '退出系统',
    goToIndex: '返回首页',
    tableColumn: {
        index: '序号',
        id: 'id',
        devicesName: '名称',
        MountChannelNumber: '挂载通道号',
        RegistrationResults: '注册结果',
        devicesName2: '设备名称',
        productName: '产品名称',
        categoryName: '分类名称',
        devicesRegisterTime: '设备注册时间',
        updateTime: '更新时间',
        createTime: '创建时间',
        devicesId: '设备编号',
        onlineStatus: '状态',
		rt:'运行时间',
        wv: '电压',
        i: '电流',
        z: '主',
        f: '副',
        p: '功率',
        e: '电能',
        l: '亮度',
        s: '色温',
        time: '持续时间',
        hardWare: '硬件版本',
        softWare: '软件版本',
        tmDate: '设备时钟',
        hsipp: '网络参数',
        hs: '心跳时间',
        ipp: 'ipp',
		wlid:'网络ID',
        message: '执行结果',
        powerOnTime: '设备通电时间',
        fs: '副灯色温',
        zs: '主灯色温',
        fl: '副灯亮度',
        zl: '主灯亮度',
        fe: '副灯电能',
        ze: '主灯电能',
        fp: '副灯功率',
        zp: '主灯功率',
        fi: '副灯电流',
        zi: '主灯电流',
        rs: 'RSSI',
        tp: '温度',
        ld:'联动亮度',
        keepTime:'延时分钟',
        times:'时间',
		keepTime:'保持分钟',
		enable:'使能',
		aboTimes:'绝对时间戳',
        nickname: '用户昵称',
        username: '用户名',
        operation: '操作',
        sxdy: '三相电压',
        sxdl: '三相电流',
        sxgl: '三相功率',
        sxdn: '三相电能',
        longitude: '经度',
        latitude: '纬度',
        assetName:'灯杆名称',
        mountDevicesId:'挂载设备',
        lightType:'照明类型',
        sumEnergy:'能耗'
    },
    request: {
        AlarmInfoOperationData:{
            0:'设备',
            1:'无经纬度'
        },
        limitSize: '请求数据大小超出允许的5M限制，无法进行防重复提交验证。',
        intervalError: '数据正在处理，请勿重复提交',
        download: {
            Loading: '正在下载数据，请稍候',
            zipError: '下载文件出现错误，请联系管理员！'
        },
        errorCode401Fun: {
            content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
            confirmButtonText: '重新登录',
            passwordError: '用户名或密码错误，请重新登录。',
            NetworkError: '后端接口连接异常',
            timeout: '系统接口请求超时',
            RequestErrorbefore: '系统接口',
            RequestErrorafter: '异常',



        },
    },
    FormC: {
        edit: '编辑',
        add: '新建',
        dellMore: '批量删除',
        ElMessageBox: {
            tips: '系统提示',
            titleEnd: '吗？',
            title: '确定要删除吗？',
            title2: '确定要批量删除吗？',
            title3: '请选择要批量删除的数据',
            title4: '请选择省市区（镇）',
            title5: '超级用户无法访问此页面，点击确定返回用户管理页',
            title6: '确定要清空日志吗？',
            title7: '确定要重启吗？',
            title8: '确定要重置吗？',
            titleUser: '请选择用户再进行操作',
            titleExport: '请选择要导出的数据',
            titleSearch: '请输入再搜索',

            error: '删除失败，请联系管理员。'
        },
        avatarUploader: {
            loading: '上传中...',
            title: '应用图标',
            title2: '请点击上传',
            text: '仅支持大小为2MB以内的图片',
            error: '上传的图片不能大于 2MB!'
        },
        del: '删除',
        Close: '关闭',
        cancel: '取消',
        cancel2: '已取消',
        operation: '操作',
        operation2: '分组控制',
        confirm: '确定',
        confirm2: '确定',
        placeholder: '请选择',
        placeholder2: '请选择时间',
        placeholder3: '输入产品编号查找',
        error: '操作失败',
        success: '操作成功',
        success2: '调整成功',
        rules: {
            time: '请设置持续时间',
            size: '长度最小为1最大为20',
            ip: '请输入ip.示例：127.0.0.1',
            ip2: 'ip式错误，请重新输入.示例：127.0.0.1'
        },
        inputDefPlaceholder: '请填写',
        Uploader:'上传文件：'
    },
    login: {
        left: {
            LampControl: '灯控',
            DistributionCabinet: '配电柜',

        },
        form: {
            username: '用户名：',
            password: '密  码：',
            rememberMe: '记住密码',
            Login: '登录',
			record:'粤ICP备2021129348号'
        },
        rules: {
            username: '请填写用户名',
            password: '请填写密码'
        }
    },
    index: {
        header: {
            sunrise: '日出：',
            sunset: '日落：',
            enterSys: '后台',
            selectProject: '应用',
            regShowFun: '查看操作结果',
            regShowBox: '操作结果',
            regShowBoxOptionBox: '查看详情',
            json: {
                lightSource: '灯控',
                lightSource2: '光源',
                DistributionCabinet: '配电柜',
                LampPost: '灯杆',

            }
        },
        guangyuanOptionBox: {
            title: '批量开关灯',
            label: '分组名称：'
        },
        deviceInfo: {
            totalName: '设备总数',
            json: {
                on: '开灯',
                off: '关灯',
                lightOnOffTime: '设备开关灯时间',
                lightOnRate: '设备亮灯率趋势图',
                onlineRate: '在线率',
                offlineRate: '离线率',
				onlineCount:'在线数',
				offlineCount:'离线数',
                leakageMonitoring: '漏电检测'
            }
        },
        ruleStrategy: {
            TitleH2: '策略列表',
            on: '启用',
            off: '停用'
        },
        alarmInfo: {
            TitleH2: {
                0: '告警名称',
                1: '告警等级',
                2: '告警列表',
                3: '工单'
            },
            table: {
                warningName: '告警名称',
                warningLevel: '告警等级',
                updateTime: '告警时间',
                warningStatus: '状态',
                option: '操作',
                error: '请从中间菜单栏选择灯杆再定位到告警设备',
                error2: '告警信息出错，请联系管理员'
            },
            json: {
                shebei: '设备类',
                yunwei: '运维类',
                nenghao: '能耗类',
                yiban: '普通报警',
                jiaoda: '较大报警',
                yanzhong: '严重报警',
                normal: '正常'
            }
        },
        workOrderInfo: {
            lightOnOffTimeUndefined: '暂无数据',
            lightOnRate: {
                0: '未处置',
                1: '已处置'
            },
            lightOnOffTime: '工单分类',
            lightOnRate: '工单处置率'
        },
        log: {
            sys: '系统日志',
            device: '设备日志'
        },
        bottom: {
            btnBox: '能耗趋势图',
            typeArr: {
                1: '近7天',
                2: '近一个月',
                3: '近一年',
            },
            setData: {
                localEnergy: '当前能耗',
                sumEnergy: '总能耗',
                weekEnergy: '周能耗',
                monthEnergy: '月度能耗',
                yearEnergy: '年度能耗'
            }
        },
        baiduMap: {
            totalNumMarkerArrShowFun2: '回到设备总数视图',
            backAreaCenterTooltip: {
                0: '回到',
                1: '中心'
            },
            viewMarkerItem: {
                0: '查看',
                1: '的设备'
            },
            expandMapFun: {
                0: '扩大视图',
                1: '恢复视图'
            },
            heatmapShow: {
                0: '显示热力图',
                1: '隐藏热力图'
            },
            AlarmInfoOperationDataNull: '设备信息错误，请联系管理员。',
            viewMarkerItemUndefined: '设备定位错误，请在后台管理重新设置。',
			noCityOrErea:'未选择选择省市区，请先选择！',
            searchBox: {
                placeholder: '请输入设备ID'
            },
            ztList: {
                sxdy: '三相电压：',
                sxdl: '三相电流：',
                sxgl: '三相功率：',
                sxdn: '三相电能：',
                height: '高度：',
                weight: ',宽度：',
                length: ',长度：',
                0: '离线',
                1: '在线',
                wv: '工作电压：',
                wvUnit: 'V，',
                sl1: {
                    zl: '亮度：',
                    zs: '色温：',
                    zi: '工作电流：',
                    ziUnit: 'A',
                    ze: '，工作能耗：',
                    zeUnit: 'KW'
                },
                sl2: {
                    zl: '主灯亮度：',
                    fl: ',副灯亮度：',
                    zs: '主灯色温：',
                    fs: ',副灯色温：',
                    zi: '主灯工作电流：',
                    fi: '，副灯工作电流：',
                    ze: '，主灯工作能耗：',
                    fe: '，副灯工作能耗：'
                }
            },
            operationBox: {
                zl: '主亮度',
                fl: '副亮度',
                l: '亮度',
                zs: '主色温',
                fs: '副色温',
                s: '色温',
                time: {
                    error1: '请选择最小两分钟后的时间',
                    error2: '请在45天之内选择',
					error3:'请选择最小一分钟后的时间'
                },
                sl2: {
                    0: '主',
                    1: '副'
                },
                minute: '分钟',
                offOnSwitch: {
                    0: '关灯',
                    1: '开灯'
                }
            },
            contentBox: {
                denggan: '灯杆详情',
                Alarm: '告警详情',
                deviceId: '灯杆挂载灯控设备名称',
                deviceIds: '选择设备调光'
            },
            alarmCheckbox: {
                title: '告警等级'
            },
            json: {
                markerArrInfoList: {
                    installPosition: '所在位置',
                    attributes: '属性',
                    attributes2: '电压电流',
                    denggan: {
                        name: '灯杆名称',
                        num: '灯杆挂载灯控设备数',
                        id: '灯杆ID',
                        time: '创建时间'
                    },
                    AlarmInfo: {
                        name: '设备名称',
                        id: '设备ID',
                        time: '告警时间',
                        state: '告警状态',
                        chaoshi: '告警是否超时',
                        type: '告警类型',
                        email: '告警推送邮箱'
                    }
                }
            }
        },
        selectProject: {
            add: '创建应用',
            title: '切换应用',
            Search: '输入关键字搜索应用',
            loadEnd: '已经到底了',
            GIS: '聚焦GIS：',
            createTime: '创建时间：',
            appName: '应用名称',
            appNameRule: '请填写应用名称',
            provinceCity: '聚焦GIS',
            provinceCityRule: '请选择聚焦GIS'
        }
    },
    mag: {
        router: {
            assetindex: '资产管理',
            assetview: '查看资产分组',
            deviceinfoTitle: '设备管理',
            deviceinfo: '设备信息详情',
            devicegroupMag: '分组管理',
            devicegroupMagview: '分组管理详情',
            alarmindex: '告警工单',
            ruleStrategyindex: '规则策略',
            logindex: '日志',
            deviceController: '设备控制',
            user: '用户管理',
            deviceSearch: '设备查找',
			communication :'通信管理',
            Setting:'配置',
            energy:'能耗统计'
        },
        components: {
            tableBox: {
                description: '加载中',
                option: '操作',
                edit: '编辑',
                delSuccess: '删除成功！',
                delError: '删除失败，请联系管理员',
                pagination: {
                    pagesize: "条/页",
                    total: "总计：",
                    pageClassifier: "跳转",
                }
            },
            addEditDrawer: {
                edit: '编辑',
                addSuccess: '添加',
                success: '成功！',
                error: '添加失败，请联系管理员'
            },
            searchBox: {
                add: '新增'
            },
            searchDiv: {
                searchPlaceholder: '搜索',
                PopoverBox: {
                    title: '高级搜索'
                }
            },
            leftTree: {
                title: '设备树状图',
                placeholder: '请输入关键词搜索'
            }
        },
        tabsList: {
            Light: '灯控',
            Pole: '灯杆',
            Ggd: '配电柜',
            jcqnb: '其他年表配置',
			tgnb:'调光年表配置',
            lightTypeMag: '照明类型'
        },
        alarm: {
            tabsList: {
                warning: '告警',
                workOrder: '工单'
            },
            btnList: {
                add: '新建工单'
            },
            tableColumn: {
                id: '告警ID',
                warningName: '告警名称',
                devicesId: '设备编号',
                warningType: '告警类型',
                warningLevel: '告警等级',
                warningStatus: '告警状态',
                warningDeal: '告警处置',
                updateTime: '最后一次更新时间'
            },
            tableColumn2: {
                warningIds: '关联告警ID',
                name: '工单名称',
                warningNames: '关联告警名称',
                responsiblePerson: '工单派遣名单',
                responsibleEmail: '工单派遣邮箱',
                workStatus: '工单处理状态',
                updateTime: '最后一次更新时间'
            },
            addEditDrawer: {
                warningDealId: '告警处置：',
                rules: {
                    warningDealId: '请选择告警处置'
                }
            },
            addEditDrawer2: {
                warningIds: '告警名称：',
                name: '工单名称：',
                category: '工单分类：',
                responsiblePerson: '工单派遣名单：',
                responsibleEmail: '工单派遣邮箱：',
                workStatus: '工单处理状态：',
                rules: {
                    workStatus: '请选择工单处理状态',
                    warningIds: '请选择告警名称',
                    category: '请选择工单分类',
                    name: '请填写工单名称',
                    responsiblePerson: '请填写工单派遣名单',
                    responsibleEmail: '请填写工单派遣邮箱'
                }
            }
        },
        asset: {
            tableColumn: {
                name: '资产分组名称',
                companyName: '所属单位',
                assetLightPoleCount: '灯杆数量',
                assetLightCount: '灯控数量',
                assetGgdCount: '配电柜数量',
                updateTime: '最后一次更新时间'
            },
            addEditDrawer: {
                name: '资产分组名称：',
                companyName: '所属单位：',
                groupIds: '选择分组：',
                rules: {
                    name: '请填写资产组合名称',
                    companyName: '请填写所属单位',

                },
            },
            view: {

                tableColumn: {
                    name: {
                        Ggd: '资产配电柜名称',
                        Pole: '资产灯杆名称',
                        Light: '灯控名称'
                    },
                    deviceId: '设备编号',
                    installPosition: '安装位置',
                    installTime: '安装时间',
                    height: '高度',
                    weight: '宽度',
                    longitude: '经度',
                    latitude: '纬度',
                    updateTime: '最新更新时间',
                    material: '材质',
                    poleType: '灯杆类型',
                    armType: '灯臂类型',
                    lightPoleName: '灯杆名称',
                    lampCapType: '光源类型',
                    lampCapCount: '光源数量',
                    assetGgdName: '所属配电柜'


                },
                addEditDrawer: {
                    deviceId: '设备：',
                    hlbh: '回路编号',
					hlkg:'回路开关',
                    name: {
                        Ggd: '资产配电柜名称：',
                        Pole: '资产灯杆名称：',
                        Light: '灯控名称：'
                    },
                    assetGgdId: '配电柜：',
                    provinceCityArea: '选择省市区（镇）：',
                    installPosition: '安装位置：',
                    installTime: '安装时间：',
                    height: '高度：',
                    weight: '宽度：',
                    longitude: '经度：',
                    latitude: '纬度：',
                    material: '材质：',
                    poleType: '灯杆类型：',
                    armType: '灯臂类型：',
                    lightPoleId: '灯杆：',
                    lampCapType: '光源类型：',
                    lampCapCount: '光源数量：',
                    rules: {
                        deviceId: '请选择设备',
                        name: {
                            Ggd: '请填写资产配电柜名称',
                            Pole: '请填写灯杆名称',
                            Light: '请填写灯控名称'
                        },
                    }
                }
            }
        },
        device: {
            info: {
                searchPlaceholder: '请输入设备ID',
                btnList: {
                    add: '单设备注册',
                    addMore: '批量注册',
                    addLnglatMore: '批量导入经纬度',
                    update: '设备升级',
                    export: '导出'
                },
                tableColumn: {
                    devicesId: '设备编号',
                    devicesName: '设备名称',
                    regTime: '注册时间',
                    hardWare: '固件版本',
                    groupName: '分组名称',
                    productName: '产品名称',
                    categoryName: '设备分类名称',
                    onlineStatus: '状态',
                    updateTime: '最新更新时间'
                },
                addEditDrawer: {
                    devicesId: '设备编号：',
                    devicesName: '设备名称：',
                    productId: '产品：',
                    groupId: '设备分组：',
                    groupIdaddOption: {
                        0: '未分组'
                    },
                    categoryId: '分类：',
                    mountDevicesId: '挂载集中器：',
                    mountChannel: '通道',
                    onlineStatus: '在线状态：',
                    devicesRegisterTime: '设备注册时间：',
                    softWare: '软件版本：',
                    hardWare: '硬件版本：',
                    cid: '通讯卡号：',
                    imei: '设备IMEI：',
                    rules: {
                        devicesId: '请填写设备编号',
                        devicesName: '请填写设备名称',
                        productId: '请选择产品',
                        categoryId: '请选择分类'
                    }
                },
                upDate: {
                    upload__text: {
                        0: '将文件拖到此处或',
                        1: '单击上传'
                    },
                    uploading: '上传中',
                    uploadSuccessList: {
                        firmwareName: '固件名称：'
                    },
                    confirm: '升级',
                    success: '操作成功，正在升级中。',
                    uploadSuccessList0: '请上传固件文件',
                    formItem: {
                        productId: '产品：',
                        groupId: '设备分组：',
                    },
                    tableColumn: {
                        devicesId: '设备编号',
                        devicesName: '设备名称',
                        hardWare: '固件版本',
                        groupName: '分组名称'
                    }
                },
                addMore: {
                    modelDown: '模板下载',
                    loadFile: '加载文件',
                    register: '点击注册',
                    uploadSuccessBox: {
                        uploadSuccessList: {
                            title: '注册成功：',
                            title2: '经纬度导入成功：',
                            num: '设备编号：'
                        },
                        uploadErrorList: {
                            title: '注册失败：',
                            title2: '经纬度导入失败：',
                        }
                    }
                }
            },
            groupMag: {
                tableColumn: {
                    groupName: '分组名称',
                    productName: '产品名称',
                    status: '状态',
                    updateTime: '更新时间',
                    assetSumName: '所属资产分组'
                },
                btnList: {
                    add: '新增分组'
                },
                addEditDrawer: {
                    groupName: '分组名称：',
                    productId: '产品：',
                    status: '状态：',
                    assetSumId: '资产分组',
                    rules: {
                        groupName: '请填写分组名称',
                        assetSumId: '请选择资产分组',
                        productId: '请选择产品',
                    }
                },
                view: {
                    selection0: '请选择设备操作',
                    TableBox: {
                        add: '添加分组配置',
                        del: '取消分组配置'
                    },
                    btnList: {
                        editDevicesGroup: '批量给设备分组'
                    },
                    PopoverformItem: {
                        productId: '产品：',
                        productIdaddOption: {
                            0: '全部'
                        },
                        categoryId: '分类：',
                        assetSumId: '资产分组：'
                    },
                    tableColumn: {
                        devicesId: '设备编号',
                        devicesName: '设备名称',
                        productName: '产品名称',
                        categoryName: '设备类型名称',
                        updateTime: '设备入库时间'
                    }
                }
            }
        },
        log: {
            tabsList: {
                device: '设备日志',
                sys: '系统日志'
            },
            tableColumn: {
                devicesId: '设备编号',
                createTime: '创建时间',
                content: '日志内容',
                createNickname: '创建者昵称',
				producerName:'设备名称',
				consumerJson:'设备报文',
				producerJson:'系统报文',
				status:'状态'
            },
            btnList: {
                export: '导出',
                clear: '清空日志',
                del: '删除',
                delError: '请先选择时间'
            },
            formItem: {
                time: '时间范围：',
                type: '参数类型：',
				hlTime:'保持时间'
            }
        },
        ruleStrategy: {
            tableColumn: {
                name: '策略名称',
                type: '策略类型',
                runUnit: '执行单元',
                effectTime: '生效时间',
                invalidTime: '失效时间',
                runTime: '执行时间',
                targetIdList: '设备编号',
                status: '状态'
            },
            addEditDrawer: {
                name: '策略名称：',
                status: '状态：',
                type: '类型：',
                productId: '产品：',
                triggerId: '触发器：',
                judgmentId: '运算规则：',
                disposerId: '处置器：',
                time: '生效时间-失效时间：',
                runTime: '执行时间：',
                runUnit: '执行单元：',
                targetType: '关联目标类型：',
                targetIdList: '关联目标：',
                effectEverydayTime: '每日生效时间段：',
                effectEverydayTime1: '第1组：',
                effectEverydayTime2: '第2组：',
                rules: {
                    name: '请填写策略名称',
                    status: '请选择状态',
                    type: '请选择类型',
                    productId: '请选择产品',
                    triggerId: '请选择触发器',
                    judgmentId: '请选择运算规则',
                    disposerId: '请选择处置器',
                    runUnit: '请选择执行单元',
                    targetType: '请选择关联目标类型',
                    targetIdList: '请选择关联目标',

                }
            },
            btnList: {
                add: '新增策略',
                add1: '新增事件策略',
                add2: '新增定时策略'
            }
        },
        deviceController: {
            ggdViewBox: {
                getDDQKGGTG:'查看单灯群控光感',
                getDDZR:'查看群控单灯准时日表',
                getSxjlxs:'查看计量系数',
                HLZR: '查看回路准时日表',
				getDDJR:'查看群控单灯计时日表',
                baseInfo: '基本信息',
				getTgzr:'查看调光准时日表',
				getTgnb:'查看调光年表',
				getTgggVO:'查看光感设置',
				// '查看光感设置',
                hideinfoBox: {
                    0: '显示更多',
                    1: '隐藏'
                },
				minute:'分钟',
                getHlNb: '查看回路年表',
                JcqStatusListDataTitle: '回路状态',
				RealTimeData:'实时数据',
				AlarmWidth:'告警阔值',
                infoLabelList: {
                    4: {
                        id: '设备ID',
                        devicesName: '设备名称',
                        sxdn: '三相电能',
                        sbxh: '设备型号',
                        productName: '产品名称',
                        mzxh: '模组型号',
                        rtcsj: '设备时间',
                        yxsj: '运行时间',
                        jwd: '经纬度',
                        jwdLng: '经度',
                        jwdLat: '纬度',
                        hgqzsb: '电流互感器匝数比',
                        jzqczkg: '集中器操作开关',
						tgbc:'调光自适应补偿',
                        jwsj: '本地经纬度时间',
                        jwpyz: '本地经纬度时间偏移值',
                        jwpyzLabel:{
                            0:'日出',
                            1:'日落'
                        },
                        jcqrbms: '回路模式',
                        jcqrbmssortableArrUnit: '路',
						tgrbms:'日表模式',
						ntgv:'实时电压',
						tglj:'调光逻辑',
                        sxdy: '三相电压',
                        sxdl: '三相电流',
                        sxgl: '三相电功率',
						sxdlAll: '三相总电流',
						sxdnAll: '三相总电能',
						sxglAll: '三相总功率',
                        wdgjyz: '设备温度告警阈值',
                        temp: '设备温度',
                        sxdlgjyz: '三相电流告警阈值',
                        sxdlgjyzAmin: 'A相min',
                        sxdlgjyzAmax: 'A相max',
                        sxdlgjyzBmin: 'B相min',
                        sxdlgjyzBmax: 'B相max',
                        sxdlgjyzCmin: 'C相min',
                        sxdlgjyzCmax: 'C相max',
                        sxdygjyz: '三相电压告警阈值',
                        sxgjsn: '告警使能',
						sxgjsnA: 'A相告警使能',
						sxgjsnB: 'B相告警使能',
						sxgjsnC: 'C相告警使能',
                        sxgjsnsortableArr: {
                            0: '电压告警使能',
                            1: '电流告警使能',
                            2: '温度告警使能'
                        },
                        hardWare:'硬件版本',
                        softWare:'软件版本',
                        hlggsz:'回路光感参数',
                        hlggszArr:{
                            0:'亮度',
                            1:'回差'
                        },
                        lxkg:'轮询开关',
                        lxzq:'轮询周期',
                        sbsjzq:'心跳周期',
                        gjzq:'告警周期',
                        ddqkrbms:'单灯群控日表模式',
                        temp:'设备温度',
						wlid:'网络ID',
						kglsc:'3路开关量输出',
						kglsr:'3路开关量输入',
						ntgz:'调光值',
						dlbh:'调光线短路保护开关',
						tgdls:'短路情况',
						tgmrv:'开机默认电压'
                    }
                }
            },
            searchPlaceholder: '请填写设备编号搜索',
            tabsList: {
                Light: "MQTT",
                sys: 'TCP'
            },
            setHlgg: {
                ld: '亮度',
                hc: '回差'
            },
            dimTg:{
				tgNum:'调光编号',
				tgPercent:'调光值',
				yf:'月份'
			},
            setJwdpyz: {
                rcpyz: '日出偏移值(秒)',
                rlpyz: '日落偏移值(秒)',
				rcld:'日出亮度',
				rlld:'日落亮度'
            },
            setGJZQ: {
                gjzq: '告警周期(分钟)'
            },
            setHgqzsb: {
                hgqzsb: '电流互感器匝数比'
            },
            setSbsjzq: {
                sbsjzq: '心跳周期(秒)'
            },
            setLdcs: {
                ldsn: '联动使能',
                ldys: '联动延时时间(秒)',
                ldcs: '联动超时时间（秒）'
            },
            setJzqczkg: {
                jzqczkg: '集中器操作开关'
            },
            setKglsc: {
                kglsc1: '1路',
                kglsc2: '2路',
                kglsc3: '3路'
            },
            syncHlNb: {
                jcqNum: '回路编号',
                jcqyf: '月份'
            },
            setHlNb: {
                jcqnbId: '年表',
                jcqnbNum: '回路编号'
            },
            setHlRm: {
                jcqkg1: '1路',
                jcqkg2: '2路',
                jcqkg3: '3路',
                jcqkg4: '4路',
                jcqkg5: '5路',
                jcqkg6: '6路',
                jcqkg7: '7路',
                jcqkg8: '8路'
            },
            setHLZR: {
                todaySeconds:'时间',
                time:'时间（分钟）',
				keepTime:'时间（分钟）',
                group: {
                    0: '第',
                    1: '组',
                    2: '路'
                },
                zl: '主亮度',
                zs: '主色温',
                fl: '副亮度',
                fs: '副色温',
                ld: '联动亮度',
				ll: '联动亮度',
                jcqkg1: '1路',
                jcqkg2: '2路',
                jcqkg3: '3路',
                jcqkg4: '4路',
                jcqkg5: '5路',
                jcqkg6: '6路',
                jcqkg7: '7路',
                jcqkg8: '8路',
				
            },
			setTgzr:{
				 todaySeconds:'时间',
				 tg1: '1路 亮度',
				 tg2: '2路 亮度',
				 tg3: '3路 亮度',
				 tg4: '4路 亮度',
				 tg5: '5路 亮度',
				 tg6: '6路 亮度',
				 tg7: '7路 亮度',
				 tg8: '8路 亮度'
			},
            setSxjlxs: {
                groupList: {
                    0: "三相电压计量系数",
                    1: "三相电流计量系数",
                    2: "三相功率计量系数",
                },
                labelList: {
                    A: 'A相:',
                    B: 'B相:',
                    C: 'C相:',
                },
                labelList2: {
                    A: 'A相',
                    B: 'B相',
                    C: 'C相',
                }
            },
            batchIPP: {
                hs: '心跳时间',
                ip: 'IP',
                port: '端口'
            },
            setLxzq:{
                lxzq:'轮询周期(分钟)'
            },
            setJzqckg:{
                jzqczkg:'开关'
            },
            tgByGroup:{
                lightTypeId:'照明类型'
            },
            setDDQKRBMS:{
                ddqkrbms:'单灯群控日表模式'
            },
			setTgrbms:{
			    tgrbms:'8路调光日表模式'
			},
            setDDQKGGTG:{
                defaultL:'默认亮度',
                defaultS:'默认色温',
                tgStepNum:'调光步值',
                tgMin:'调光最低值',
                tgMax:'调光最高值'
            },
            setWSGD:{
                wsgd:'外设供电'
            },
            setGpnum:{
                lightTypeId:'照明类型' 
            },
            setTM:{
                tm:''
            },
            setLxkg:{
                lxkg:'轮询开关'
            },
            setWlid:{
                wlId:'网络id'
            },
            fotaCenterLight:{
                subSize:'分包大小',
                retryNum:'重试次数',
                intervalTime:'重试间隔（毫秒）',
                firmwareId:'升级固件ID'
            },
            view: {
                tabs: {
                    list: '列表',
                    echart: '图表',
                    getr:'查看日表',
                    getJr:'查看计时日表',
                    getZr:'查看准时日表'
                },
				nowTr:'当前选择日表：',
                getZr:'准时日表',
                getJr:'计时日表'
            },
            error: '请选择设备再执行操作',
            json: {
                btnList: {
                    Light: {
                        jzqzhining:'集中器指令',
                        kzhl:'控制回路',
                        qkdd:'群控单灯',
                        rjl: '同步计量系数',
                        rd: '召测',
                        lightOption: '调光',
						dimLightOption:'调光',
                        syncTime: '校准时钟',
                        as: '读取设备属性',
                        reb: '重启',
                        SIM: '读取设备SIM',
                        batchReadIPP: '读取网络参数',
                        batchIPP: '设置网络参数',
                        setGP: '设置经纬度',
                        setLdcs: '联动参数',
                        skhl: '手控回路',
                        setHlgg: '设置回路光感',
                        setJwdpyz: '设置经纬度偏移值',
                        setGJZQ: '设置告警周期',
                        setHgqzsb: '设置电流互感器匝数比',
                        setKglsc: '设置3路开关量输出',
                        setSbsjzq: '设置心跳周期',
                        setJzqczkg: '设置集中器屏幕使能',
                        syncRgj: '同步设备告警参数',
                        syncJwd: '同步本地经纬度参数',
                        syncRhlgg: '同步回路光感参数',
                        setHlNb: '设置回路年表',
                        syncHlNb: '同步回路年表',
                        setHlRm: '回路日表模式',
                        setHLZR: '设置准时日表',
                        getHLZR: '同步准时日表',
                        getSxjlxs: '同步计量系数',
                        setSxjlxs: '设置计量系数',
                        syncDDZR: '同步群控单灯准时日表',
                        setDDZR: '设置群控单灯准时日表',
                        setBmd: '设置白名单',
                        syncBmd: '获取白名单',
                        syncHLZR:'同步回路准时日表',
                        syncDDQKGGTG:'同步单灯群控光感设置',
                        setLxzq:'设置轮询周期',
                        setJzqckg:'设置集中器屏幕使能',
                        tgByGroup:'手控组灯',
                        setDDQKRBMS:'设置单灯群控日表模式',
                        setDDQKGGTG:'设置单灯群控光感',
                        setWSGD:'设置外设供电',
                        reset:'重置设备',
                        setGpnum:'设置照明类型',
                        syncGpnum:'同步照明类型',
                        setTM:'时钟校准',
                        readTm:'读取设备时钟',
                        setLxkg:'设置轮询开关',
                        syncJr:'同步计时日表',
                        syncZr:'同步准时日表',
                        syncDDQKJR:'同步单灯群控计时日表',
                        setDDQKJR:'设置单灯群控计时日表',
                        setWlid:'设置网络ID',
                        syncWlid:'同步网络ID',
                        fotaCenterLight:'集中器单双灯升级',
						setTgrbms:'设置调光日表模式',
						setTgnb:'设置调光年表',
						setTgzr:'设置调光准时日表',
						setTggg:'光感调光设置',
						setTgbc:'调光自适应补偿',
						jzqczkg:'集中器操作开关',
						syncTgnb:'同步调光年表',
						syncTgzr:'同步调光准时日表',
						syncTggg:'同步光感调光参数',
						async8DV:'同步8路调光值',
						async3SI:'同步3路开关量输入',
						async3SO:'同步3路开关量输出',
						clearCache:'清除指令缓存',
						setTglj:'设置调光逻辑',
						save:'线路保护开关',
						setTgmrv:'设置调光默认值'
                    }
                }
            }

        },
        user: {
            status1: '注销',
            status2: '启用',
            status3: '正常',
            passwordTips: '请输入您的密码',
            passwordTips2: '输入您的密码',
            drawChangePw: '修改密码',
            message: ',准备重新登录',
            passwordRule: '请再次输入密码',
            passwordRule2: '两次密码输入不一致',
			searchPlaceholder:'请输入系统昵称',
            tableColumn: {
                username: '用户名称',
                nickname: '系统昵称',
                createTime: '创建日期',
                appCount: '项目总数',
                devicesCount: '设备总数',
                status: '状态',
                appName: '项目名称',
                updateTime: '创建日期',

            },
            addEditDrawer: {
                username: '用户名称：',
                nickname: '系统昵称：',
                userType: '用户类型：',
                password: '密码：',
                contactPhone: '联系方式：',
                devicesCount: '设备总数：',
                status: '状态：',
                rules: {
                    username: '请填写用户名称',
                    nickname: '请填写系统昵称',
                    userType: '请选择用户类型',
                    password: '请填写初始密码',
                    contactPhone1: '请输入手机号码',
                    contactPhone2: '手机号码格式错误，请重新输入'
                }
            }
        },
        header: {
            oldPassword: '旧密码',
            newPassword: '新密码',
            renew: '再次输入密码',
            oldPasswordRule: '请填写旧密码',
            newPasswordRule: '请填写新密码'
        },
        setting: {
            tableColumn: {
                jcqnb: {
                    name: '年表名称',
                    time: '日期范围',
                    ['1_time']: '动作1',
                    ['2_time']: '动作2',
                    ['3_time']: '动作3',
                    ['4_time']: '动作4',
					text:'*当亮度为101时，开启光感调光模式'
                },
                lightTypeMag: {
                    groupNum: '组号',
                    id: '照明类型ID',
                    lightType: '照明类型',
                    add: '新增照明类型'
                }
            }
        },
        energy:{
            
        },
		communication:{
			searchPlaceholder:'接入号、ICCID、IMSI',
			btnList:{
				balanceDisplay:'余额显示',
				balanceRecharge:'余额充值',
				updateData:'更新新运营商数据',
			},
			title:{
				paymentMethod:'支付方式',
				wechat:'微信',
				Alipay:'支付宝',
				totalAmount:'总金额',
				topUp:'充值'
			},
			tableColumn:{
				msisdn:'接入号',
				iccid:'ICCID',
				imsi:'IMSI',
				operator:'运营商',
				orgName:'客户名称',
				dueDate:'到期日期',
				workingCondition:'卡状态',
				lifeCycle:'生命周期',
				remainGprs:'余额(MB)',
				useGprs:'当月用量(MB)',
				totalGprs:'套餐总量(MB)',
				comboName:'套餐',
				comboType:'套餐类型',
				waitType:'沉默期类型',
				waitDate:'沉默期到期日期',
				testType:'测试期类型',
				testDate:'测试期到期日期',
				isOrienteer:'定向',
				totalVoice:'语音总量(分钟)',
				useVoice:'语音用量(分钟)',
				remainVoice:'语音余量(分钟)',
				customerName:'客户名称',
				accountBalance:'账户余额',
			}
		}
    }
}